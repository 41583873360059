const AciHwReleaseNoticeKeys = {
  TITLE: "title",
  ID: "id",
  PARTY_ID: "partyId",
  HEADER_ID: "headerId",
  ENTRY_NUMBER: "entryNumber",
  RECEIVED_DATE: "receivedDate",
  KEY_TYPE: "keyType",
  SERVICE_OPTION_ID: "serviceOptionId",
  CUSTOMS_OFFICE_CODE: "customsOfficeCode",
  WAREHOUSE_CODE: "warehouseCode",
  CBSA_PROCESSING_DATE: "cbsaProcessingDate",
  RELEASE_DATE: "releaseDate",
  PROCESSING_IND: "processingInd",
  DELIVERY_INST1: "deliveryInst1",
  DELIVERY_INST2: "deliveryInst2",
  REJECT_COMMENTS1: "rejectComments1",
  REJECT_COMMENTS2: "rejectComments2",
  CCN: "ccn",
  SENDER_MSG_REF_NO: "senderMsgRefNo",
  REJECT_TYPE: "rejectType",
  APP_ERROR_CODE: "appErrorCode",
  ERROR_DESC:"errorDesc",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  VERSION: "version",
  Containers: {
    TITLE: "containers.title",
    CONTAINER_NUMBER: "containers.containerNumber"
  }
}

export default AciHwReleaseNoticeKeys
